import React from "react";
import Layout from "../components/layout";
import Helmet from "react-helmet";

import gallery1 from "../static/ordinace_008.jpg";
import gallery1full from "../static/ordinace_008full.jpg";
import gallery2 from "../static/ordinace_014.jpg";
import gallery2full from "../static/ordinace_014full.jpg";
import gallery3 from "../static/ordinace_015.jpg";
import gallery3full from "../static/ordinace_015full.jpg";

import image1 from "../static/mapa_blansko_small.jpg";
import image2 from "../static/mapa_letovice_small.jpg";
import image3 from "../static/mapa_svitavy_small.jpg";

export default () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Ordinace | MUDr. Rastislav Šušol</title>
    </Helmet>

    <div id="page" className="seven columns">
      <div className="inner">
        <div id="page-header">
          <h1 id="page-title" className="title">
            Ordinace
          </h1>
        </div>

        <div id="content" className="content clearfix">
          <h2>Blansko</h2>

          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            style={{ width: `100%` }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    width: `100%`,
                    textAlign: `left`,
                    verticalAlign: `top`
                  }}
                >
                  <h5>Adresa</h5>
                  <p>
                    K.H.Máchy 17, Blansko
                    <br />
                    (Budova záchranné služby)
                  </p>
                  <h5>Kontakt</h5>
                  <p>tel.: 733 740 449</p>
                  <h5>Ordinační hodiny</h5>
                  <p>Úterý, Středa, Pátek 7.00 - 13.00 hod.</p>
                </td>
                <td>
                  <a
                    href="https://maps.google.com/maps?f=q&amp;source=embed&amp;hl=en&amp;geocode=&amp;q=K.+H.+M%C3%A1chy+17,+Blansko,+Czech+Republic&amp;aq=3&amp;oq=K.H.M%C3%A1chy+17&amp;sll=48.672597,19.696058&amp;sspn=2.883811,7.371826&amp;t=h&amp;ie=UTF8&amp;hq=&amp;hnear=Karla+Hynka+M%C3%A1chy+2342%2F17,+678+01+Blansko,+Czech+Republic&amp;ll=49.362071,16.651969&amp;spn=0.001397,0.002135&amp;z=17&amp;iwloc=A"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt=""
                      src={image1}
                      style={{ width: "200px", height: "200px" }}
                    />
                  </a>
                  <br />
                  <small>
                    <a
                      href="https://maps.google.com/maps?f=q&amp;source=embed&amp;hl=en&amp;geocode=&amp;q=K.+H.+M%C3%A1chy+17,+Blansko,+Czech+Republic&amp;aq=3&amp;oq=K.H.M%C3%A1chy+17&amp;sll=48.672597,19.696058&amp;sspn=2.883811,7.371826&amp;t=h&amp;ie=UTF8&amp;hq=&amp;hnear=Karla+Hynka+M%C3%A1chy+2342%2F17,+678+01+Blansko,+Czech+Republic&amp;ll=49.362071,16.651969&amp;spn=0.001397,0.002135&amp;z=17&amp;iwloc=A"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Zobrazit větší mapu
                    </a>
                  </small>
                </td>
              </tr>
            </tbody>
          </table>

          <h3>Galerie</h3>
          <table
            border="0"
            cellpadding="1"
            cellspacing="1"
            style={{ width: "100%" }}
          >
            <tbody>
              <tr>
                <td>
                  <a
                    className="colorbox colorbox-insert-image init-colorbox-processed cboxElement"
                    href={gallery1full}
                    rel="gallery-all"
                    style={{ textDecoration: "underline" }}
                  >
                    <img
                      alt=""
                      className="image-medium"
                      src={gallery1}
                      typeof="foaf:Image"
                    />
                  </a>
                </td>
                <td style={{ textAlign: "center" }}>
                  <a
                    className="colorbox colorbox-insert-image init-colorbox-processed cboxElement"
                    href={gallery2full}
                    rel="gallery-all"
                    style={{ textDecoration: "underline" }}
                  >
                    <img
                      alt=""
                      className="image-medium"
                      src={gallery2}
                      typeof="foaf:Image"
                    />
                  </a>
                </td>
                <td style={{ textAlign: "center" }}>
                  <a
                    className="colorbox colorbox-insert-image init-colorbox-processed cboxElement"
                    href={gallery3full}
                    rel="gallery-all"
                    style={{ textDecoration: "underline" }}
                  >
                    <img
                      alt=""
                      className="image-medium"
                      src={gallery3}
                      typeof="foaf:Image"
                    />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <p>&nbsp;</p>

          <h2>Letovice</h2>

          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            style={{ width: "100%" }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    width: "100%",
                    textAlign: "left",
                    verticalAlign: "top"
                  }}
                >
                  <h5>Adresa</h5>
                  <p>Pod Klášterem 17, Letovice</p>
                  <h5>Kontakt</h5>
                  <p>tel.: 516 426 114</p>
                  <h5>Ordinační hodiny</h5>
                  <p>Čtvrtek 7.00 - 11.00 hod.</p>
                </td>
                <td>
                  <a
                    href="https://maps.google.com/maps?q=Pod+klášterem+17,+Letovice,+Czech+Republic&amp;hl=en&amp;ie=UTF8&amp;ll=49.544676,16.579528&amp;spn=0.003947,0.010568&amp;sll=49.362306,16.652071&amp;sspn=0.001981,0.005284&amp;oq=Pod+Klášterem+17,+Czech+Republic&amp;hnear=Pod+klášterem+55%2F17,+679+61+Letovice,+Czech+Republic&amp;t=h&amp;z=17"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt=""
                      src={image2}
                      style={{ width: "200px", height: "200px" }}
                    />
                  </a>
                  <br />
                  <small>
                    <a
                      href="https://maps.google.com/maps?q=Pod+kl%C3%A1%C5%A1terem+17,+Letovice,+Czech+Republic&amp;hl=en&amp;ie=UTF8&amp;ll=49.544676,16.579528&amp;spn=0.003947,0.010568&amp;sll=49.362306,16.652071&amp;sspn=0.001981,0.005284&amp;oq=Pod+Kl%C3%A1%C5%A1terem+17,+Czech+Republic&amp;hnear=Pod+kl%C3%A1%C5%A1terem+55%2F17,+679+61+Letovice,+Czech+Republic&amp;t=h&amp;z=17"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Zobrazit větší mapu
                    </a>
                  </small>
                </td>
              </tr>
            </tbody>
          </table>

          <p>&nbsp;</p>

          <h2>Svitavy</h2>

          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            style={{ width: "100%" }}
          >
            <tbody>
              <tr>
                <td style={{ verticalAlign: "top", width: "794px" }}>
                  <h5>Adresa</h5>
                  <p>Hybešova 2, Svitavy</p>
                  <h5>Kontakt</h5>
                  <p>tel.: 739 995 490</p>
                  <h5>Ordinační hodiny</h5>
                  <p>
                    Pondělí 6:00 - 8:00, 13:00 - 16:00
                    <br />
                    Čtvrtek 12.00 - 15.00 hod.
                  </p>
                </td>
                <td>
                  <a
                    href="https://www.google.com/maps/place/Hybešova+2,+568+02+Svitavy,+Czechia/@49.7516579,16.4719984,18z/data=!4m13!1m7!3m6!1s0x470d8a2ad6960d3b:0x419ad24a9b914e86!2sHybešova+2,+568+02+Svitavy,+Czechia!3b1!8m2!3d49.751502!4d16.472836!3m4!1s0x470d8a2ad6960d3b:0x419ad24a9b914e86!8m2!3d49.751502!4d16.472836?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt=""
                      src={image3}
                      style={{ width: "200px", height: "200px" }}
                    />
                  </a>
                  <br />
                  <small>
                    <a
                      href="https://www.google.com/maps/place/Hybešova+2,+568+02+Svitavy,+Czechia/@49.7516579,16.4719984,18z/data=!4m13!1m7!3m6!1s0x470d8a2ad6960d3b:0x419ad24a9b914e86!2sHybešova+2,+568+02+Svitavy,+Czechia!3b1!8m2!3d49.751502!4d16.472836!3m4!1s0x470d8a2ad6960d3b:0x419ad24a9b914e86!8m2!3d49.751502!4d16.472836?hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Zobrazit větší mapu
                    </a>
                  </small>
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div id="right" className="five columns">
      <div className="region region-sidebar-second">
        <div id="block-block-2" className="greenblock block block-block">
          <div className="inner">
            <h5 className="title">Objednejte se</h5>
            <div className="content">
              <p>
                Pacienty objednáváme na určitý čas, objednat se je možno
                telefonicky nebo osobně.
              </p>
              <p>
                Pacient s akutními potížemi je ošetřený přednostně, i bez
                předešlého objednání.
              </p>
              <p>
                K vyšetření nevyžadujeme doporučení praktického nebo jiného
                lékaře.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
